import React from "react"
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from "@mdx-js/react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SSRProvider from 'react-bootstrap/SSRProvider'

import AdmissionsOverviewBottomMenu from '../../../../components/admissionsOverviewBottomMenu'
import ContactForm from "../../../../components/forms/contactFormStyled";
import DefaultHeader from '../../../../components/default-header'
import Footer from '../../../../components/footer'
import GdprPanel from "../../../../components/gdpr"
import Navigation from '../../../../components/navigation'
import Seo from '../../../../components/seo'
import SpeakWithAdmissions from "../../../../components/speakWithAdmissions"

import '../../../../styles/admissions-overview.scss'
import NewsletterSignup from "../../../../components/newsletterSignUp";

const Page = ({ data }) => {

    const shortcodes = {
        Container,
        Row,
        Col,
        SpeakWithAdmissions,
        AdmissionsOverviewBottomMenu,
        ContactForm
      }

    return (      
        <>  
        <SSRProvider>
            <Navigation />
            <DefaultHeader type="xl" headline={data.mdx.frontmatter.headline} heroBackground={data.mdx.frontmatter.heroBackground} heroMobileBackground={data.mdx.frontmatter.heroMobileBackground} subHeadline={data.mdx.frontmatter.subheadline} />
            <MDXProvider components={shortcodes}>
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </MDXProvider>   
            <Footer />
            <GdprPanel />
            <NewsletterSignup />
        </SSRProvider>
        </>
    )
}

export const Head = ({data}) => (
    <Seo 
        title={data.mdx.frontmatter.metadata.Title} 
        description={data.mdx.frontmatter.metadata.Description} 
        pathname={data.mdx.frontmatter.metadata.Link}
        ogImage={data.mdx.frontmatter.metadata.ogImage}
        cardImage={data.mdx.frontmatter.metadata.cardImage}
    />
)

export const query = graphql`
    query AdmissionsOverviewPageQuery {
        mdx(fileAbsolutePath: {regex: "/admissions-overview.mdx/"})  {
            frontmatter {
                heroBackground
                heroMobileBackground
                subheadline
                headline
                title
                metadata {
                    Description
                    Keywords
                    Link
                    Title
                    ogImage
                    cardImage
                }
            }
            body
        }
    }    
`

export default Page